import * as React from 'react';
import { useState } from 'react'
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { MenuItem, Select, FormControl, InputLabel, Button, Switch, FormControlLabel } from '@mui/material'
import { Captcha } from './Captcha'
import { Terms } from "./Terms"
import Modal from 'react-bootstrap/Modal'
import { Loader } from '../components/Loader'
import { useCardContext } from "../context/CardContext"
import firebase from "firebase/compat"
import { getFirestore } from '../services/getFirestore'
import { BikePanel } from "./BikePanel"
import NewBikeForm from "./NewBikeForm"


const theme = createTheme();

export const ExtraDataForm = (props) => {


    const [grupo, setGrupo] = useState('Desconocido')
    const [modalLengthShow, setModalLengthShow] = useState(false)
    const [checkedCaptcha, setCheckedCaptcha] = useState(false)
    const [checkedTerms, setCheckedTerms] = useState(false)
    const [valorDisabled, setValorDisabled] = useState(true)
    const [modalShow, setModalShow] = useState(false) 
    const [modalLoaderShow, setModalLoaderShow] = useState(false)
    const [redirect, setRedirect] = useState(false) 

    const {emailUser, isQR} = useCardContext()


    const sendExtraData = (event) => {
        event.preventDefault()
        setModalLoaderShow(true)
        let dni = event.target.inputDni.value
        let blood = event.target.inputBlood.value
        let trustedName = event.target.trustedName.value
        let trustedPhone = event.target.trustedPhone.value
        if(dni.length > 10 || trustedPhone.length > 20) setModalLengthShow(true)
        else {
            let extraData = {
                nombre: emailUser.nombre,
                apellido: emailUser.apellido,
                dni: dni,
                email: emailUser.email,
                grupoSanguineo: blood,
                trustedPerson: trustedName,
                trustedNumber: trustedPhone,
                date: firebase.firestore.Timestamp.fromDate(new Date())
            }

            const dbQuery = getFirestore()
            dbQuery.collection('usuarios').add(extraData)
            .then(() => setRedirect(true))
            .finally(() => setModalLoaderShow(false))
            .catch(err => console.log(err))
        }
    }

    
    
    function onTerms() {
        setCheckedTerms((prevCheck) => !prevCheck)
        !checkedTerms && checkedCaptcha && setValorDisabled(false)
        checkedTerms && setValorDisabled(true)
    }

    function onChange() {
        setCheckedCaptcha(true)
        checkedTerms && setValorDisabled(false)
    }


  return (
    <>
        {/* {redirect ? <BikePanel/> :  */}
        {redirect ? isQR ? <div className="marginBox"><NewBikeForm newBikeQR = {props.newBikeQR}/></div> : <BikePanel/> : 
            <ThemeProvider theme={theme}>
            
            
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>

                    <form onSubmit={(e) => sendExtraData(e)}  className="registrationInputs" >

                    <Typography component="h1" variant="h4" align="center">
                        Tu cuenta ya casi está lista
                    </Typography>
                    
                    <React.Fragment>
                        <Grid container spacing={3}>
                            <Grid className='mt-4' item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    Tus datos
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="inputBlood">Grupo Sanguíneo</InputLabel>
                                <Select
                                    labelId="inputBlood"
                                    id="inputBlood"
                                    name="inputBlood"
                                    value={grupo}
                                    label="Grupo Sanguíneo"
                                    onChange={e => setGrupo(e.target.value)}
                                >
                                    <MenuItem value={'Desconocido'}>No lo conozco</MenuItem>
                                    <MenuItem value={'O Negativo'}>O Negativo</MenuItem>
                                    <MenuItem value={'O Positivo'}>O Positivo</MenuItem>
                                    <MenuItem value={'A Negativo'}>A Negativo</MenuItem>
                                    <MenuItem value={'A Positivo'}>A Positivo</MenuItem>
                                    <MenuItem value={'B Negativo'}>B Negativo</MenuItem>
                                    <MenuItem value={'B Positivo'}>B Positivo</MenuItem>
                                    <MenuItem value={'AB Negativo'}>AB Negativo</MenuItem>
                                    <MenuItem value={'AB Positivo'}>AB Positivo</MenuItem>

                                </Select>
                            </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                id="inputDni"
                                name="inputDni"
                                label="Tu número de DNI"
                                fullWidth
                                variant="standard"
                            />
                            </Grid>

                            <Grid className='mt-4' item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    Datos de tu persona de confianza
                                </Typography>
                            </Grid>
                        
                            <Grid item xs={12} sm={6}>
                            <TextField
                                id="trustedName"
                                name="trustedName"
                                label="Nombre"
                                fullWidth
                                variant="standard"
                            />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                id="trustedPhone"
                                name="trustedPhone"
                                label="Teléfono"
                                fullWidth
                                variant="standard"
                            />
                            </Grid>
                            <Grid className='mt-5 mt-md-4 d-flex justify-content-center' item xs={12} sm={6}>
                                <FormControlLabel control={<Switch onClick={() => onTerms()} id="termsSwitch" />} label="Acepto los términos y condiciones" />
                            </Grid>
                            <Grid className='mt-sm-0 mt-md-4' item xs={12} sm={6}>

                                <Button
                                    id="termsButton"
                                    fullWidth
                                    variant="text"
                                    onClick={() => setModalShow(true)}
                                >
                                    Ver términos y condiciones
                                </Button>

                            </Grid>
                            <Grid className='d-flex justify-content-center' item xs={12}>
                                <Captcha change={onChange}/>
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={valorDisabled}
                                    >
                                    Enviar
                                </Button>
                            </Grid>

                        </Grid>
                    </React.Fragment>

                    </form>

                </Paper>
                
            </Container>


                <Terms show={modalShow} onHide={() => setModalShow(false)}/>

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={modalLengthShow}
                    onHide={() => setModalLengthShow(false)}
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Revisá los datos.
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Por favor, verificá tu DNI y/o el teléfono de contacto de tu persona de confiaza.
                    </Modal.Body>
                    <Modal.Footer>
                    <Button onClick={() => setModalLengthShow(false)}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>

                <Loader show={modalLoaderShow} onHide={() => setModalLoaderShow(false)}/>  


            </ThemeProvider>

        }
    </>

  )
}