import React from "react"
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'


export const Terms = (props) => {


    return(

        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Términos y Condiciones.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="listaTerminos">
              <li>El contenido presentado en el sitio web www.biciregistro.com.ar es una herramienta de información que cumple el objetivo de facilitar la identificación de bicicletas. La información proporcionada y volcada al sitio web es pura y exclusivamente responsabilidad del usuario. BiciRegistro no es propietario de dicha información ni de ningún objeto material registrado mediante dicha herramienta.</li>
              <li>BiciRegistro no se hará cargo por cualquier daño a la propiedad, al usuario y/o a terceros producidos por el uso indebido de la información proporcionada en www.biciregistro.com.ar.</li>
              <li>La utilización de los servicios del presente sitio web por parte del usuario supone su adhesión y aceptación a todas las condiciones generales de uso en la versión publicada en el mismo, así como a la política de protección de datos personales.</li>
              <li>Todos los derechos de propiedad intelectual del website www.biciregistro.com.ar, pertenecen a BiciRegistro. El usuario podrá visualizar la información, imprimirla y almacenarla siempre y cuando sea, única y exclusivamente, para su uso personal y privado, quedando, por tanto, terminantemente prohibida su utilización con fines comerciales, su distribución, así como su modificación o alteración.</li>
              <li>BiciRegistro se reserva el derecho a efectuar las correcciones que considere oportunas, pudiendo modificar, suprimir e incluir, unilateralmente y sin previo aviso, nuevos contenidos y/o servicios, así como la forma en que éstos aparezcan presentados y localizados. De igual forma, el cliente acepta que BiciRegistro se reserva el derecho de modificar y actualizar periódicamente las presentes condiciones de uso, así como las especificas de cada servicio. Salvo que ese establezca lo contrario, la introducción de cualquier característica que modifique los actuales servicios y/o la introducción de nuevos servicios quedarán sujetos a las presentes condiciones de uso.</li>
              <li>BiciRegistro sólo proporcionará información limitada acerca del usuario al público en general. El acceso a la información ampliada de dicho usuario sólo es autorizada por el mismo, quien se hará responsable por los daños y perjuicios causados por el uso indebido de dicha información.</li>
              <li>BiciRegistro proporcionará información ampliada a los servicios de emergencia en caso de accidente en la vía pública. Así mismo, BiciRegistro no se hará responsable por el uso indebido de dicha información.</li>
              <li>La información acerca de la localización de un rodado sólo es accesible por el propietario de la misma. Dicha información no será divulgada por BiciRegistro a terceros. Así mismo, BiciRegistro no se hará responsable por cualquier daño y perjuicio derivado del uso indebido de dicha información.</li>
              <li>BiciRegistro no garantiza la disponibilidad y continuidad del funcionamiento del sitio web y de los servicios.</li>
              <li>Desde el momento en que realizo mi inscripción al registro, manifiesto que de forma voluntaria transmito información personal a BiciRegistro. Igualmente, acepto que mis datos sobre la titularidad de la propiedad de la bicicleta inscripta puedan ser visualizados por terceros y autorizo a almacenar dicha información, respetando las normas de confidencialidad.</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Cerrar</Button>
        </Modal.Footer>
      </Modal>

    )
}