import React, { useEffect, useState } from 'react'
import { useCardContext } from '../context/CardContext'
import { getFirestore } from '../services/getFirestore'
import { Redirect } from 'react-router-dom'
import { Captcha } from './Captcha'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Grid, TextField, Input, Button, Typography, Container, Paper } from '@mui/material'
import firebase from "firebase/compat"
import { SpinnerDiamond } from "spinners-react"


const ImageUpload = (props) => {

    const [image, setImage ] = useState("")
    const [ url, setUrl ] = useState(props.bikeData ? props.url : "https://firebasestorage.googleapis.com/v0/b/biciregistro-5c030.appspot.com/o/foto.jpg?alt=media&token=7d1026a5-8318-430d-b419-2f55d20b84fc")
    const [imgAlert, setImgAlert] = useState('')
    const [addedBike, setAddedBike] = useState(false)
    const [valorDisabled, setValorDisabled] = useState(true)
    const [modalLoaderShow, setModalLoaderShow] = useState(false)

    const {emailUser, getImgUrl} = useCardContext()


    useEffect(() => {

        const reader = new FileReader()
        reader.addEventListener("load", () => setUrl(reader.result), false)
        if (image) reader.readAsDataURL(image)

        image.size > 1048576 && setImgAlert('Por favor, elegí una imagen de hasta 1 mb de tamaño.')
        image.type && !image.type.includes('image') && setImgAlert('Por favor, elegí una imagen')
    
    }, [image, props, getImgUrl])



    const uploadBikePanel = (e) => {
        e.preventDefault()
        setModalLoaderShow(true)
        let marca = e.target.inputContactBrand.value
        let serie = e.target.inputContactSerie.value
        let modelo = e.target.inputContactModel.value
        let descripcion = e.target.inputContactDescripcion.value

        const data = new FormData()
        if(image.size > 1048576) setImgAlert('Por favor, elegí una archivo de hasta 1 mb de tamaño.')
        else if(image.type && !image.type.includes('image')) setImgAlert('Por favor, elegí una imagen')
        else {
            data.append("file", image)
            data.append("upload_preset", "biciregistro")
            data.append("cloud_name","biciregistro")
            fetch("https://api.cloudinary.com/v1_1/biciregistro/image/upload",{
                method:"post",
                body: data
            })
            .then(resp => resp.json())
            .then(data => {

                if(!data.url) data.url = url
               
                let bike = {
                    marca: marca,
                    serie: serie,
                    modelo: modelo,
                    foto: data.url,
                    descripcion: descripcion,
                    usuario: emailUser.email,
                    date: firebase.firestore.Timestamp.fromDate(new Date())
                }
    
                const dbQuery = getFirestore()
                dbQuery.collection('bicicletas').add(bike)
                    .then(() => setAddedBike(true))
                    .finally(() => setModalLoaderShow(false))
                    .catch(err => console.log(err))

                
            })
            
            .catch(err => console.log(err))
        }
    }

    function onChange() {
        setValorDisabled(false)
    }

    const uploadImage = (e) => {
        setImgAlert('')
        setImage(e.target.files[0])
        props.imageChange && props.imageChange(e.target.files[0])
    }
    

    return (
    <>

        { modalLoaderShow ? <div className="marginSpinner align-items-center d-flex justify-content-center"><SpinnerDiamond size={100} color='#5b6c7e' /></div> :
            (
                <div>
        
                    {addedBike && <Redirect to='/usr/newAccount'/>}

                    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>

                    <form onSubmit={(e)=> props.bikeData ? props.bikeAction(e) : props.newBikeQR ? props.newBikeQR(e) : uploadBikePanel(e)} className="registrationInputs" >

                    {!props.bikeData && <Typography component="h1" variant="h4" align="center" className='mb-3'>Ingresá los datos de tu bici</Typography> }

                            <React.Fragment>
                                <Grid container spacing={3}>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="inputContactBrand"
                                            name="inputContactBrand"
                                            label="Marca"
                                            fullWidth
                                            variant="standard"
                                            required
                                            inputProps={{ maxLength: 30 }}
                                            defaultValue={props.bikeData && props.bikeData.marca}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                                id="inputContactModel"
                                                name="inputContactModel"
                                                label="Modelo"
                                                fullWidth
                                                variant="standard"
                                                required
                                                inputProps={{ maxLength: 30 }}
                                                defaultValue={props.bikeData && props.bikeData.modelo}
                                        />
                                    </Grid>
                                
                                    <Grid item xs={12}>
                                    <TextField
                                        id="inputContactSerie"
                                        name="inputContactSerie"
                                        label="Número de serie"
                                        fullWidth
                                        variant="standard"
                                        inputProps={{ maxLength: 30 }}
                                        defaultValue={props.bikeData && props.bikeData.serie}
                                    />
                                    </Grid>

                                    <Grid container align = "center" justify = "center" alignItems = "center">
                                        <Grid className='mt-4' item xs={12} sm={6}>
                                            <img className='ms-4' id='inputFoto' alt='' src={url}/>
                                        </Grid>

                                        <Grid className='mt-4' item xs={12} sm={6}>
                                            <label htmlFor="file-upload">
                                                <Input className='ms-5 ms-sm-3' accept=".jpg,.png" id="file-upload" type="file" onChange= {e=> uploadImage(e)} />
                                                <Button className='ms-md-5' variant="contained" component="span" startIcon={<CloudUploadIcon/>}>{image === "" ? !props.bikeData ? 'Subir foto' : 'Cambiar foto' : 'Cambiar foto'}</Button>
                                            </label>
                                            <div className='inputContactFoto__sizeAlert text-danger mt-2 ms-5'>{props.length ? props.imgAlert : imgAlert}</div>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            id="inputContactDescripcion"
                                            name="inputContactDescripcion"
                                            label="Descripción"
                                            multiline
                                            rows={4}
                                            placeholder="Descripción de la bicicleta (color, componentes, características que la diferencian, etc.)"
                                            inputProps={{ maxLength: 200 }}
                                            fullWidth 
                                            defaultValue={props.bikeData && props.bikeData.descripcion}
                                        />
                                    </Grid>

                                    {!props.bikeData && <Grid className='d-flex justify-content-center mt-4' item xs={12}><Captcha change={onChange}/> </Grid> }

                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            id="sendNewAccount"
                                            name='enviar'
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 2, mb: 2 }}
                                            disabled={props.bikeData ? false : valorDisabled}
                                            >
                                            {props.bikeData ? 'Actualizar' : 'Enviar'}
                                        </Button>
                                    </Grid>

                                </Grid>
                            </React.Fragment>
                
                    </form>

                    </Paper>
                        
                    </Container>
                </div>
            )
                                
        }
    </>
    )
}
export default ImageUpload;