
import ReCAPTCHA from "react-google-recaptcha";

export const Captcha = (props) => {
    return (
        <div id="captcha">
            <ReCAPTCHA
                sitekey="6Lc-qGgUAAAAADCE2ZazRGiteYEH0xVCNSjtWWZd"
                onChange={props.change}
            />
        </div>
    )
}