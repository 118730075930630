import GoogleLogin from 'react-google-login'
import React, { useState } from 'react'
import Image from 'react-bootstrap/Image'
import googleButton from'../images/btn_google_signin_dark_normal_web@2x.png'
import { ExtraDataAccount } from './ExtraDataAccount'
import { useCardContext } from '../context/CardContext'
import { SpinnerDiamond } from 'spinners-react'
import { TextField, Button } from '@mui/material'
import { getFirestore } from "../services/getFirestore"
import bcrypt from 'bcryptjs';



export const NewAccount = () => {
    
    const [redirect, setRedirect] = useState(false)
    const [modalLoaderShow, setModalLoaderShow] = useState(false)
    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')
    const [invalidData, setInvalidData] = useState(false)
    
    const {getEmailUser} = useCardContext()
     

        const responseGoogle = (response) => {
            setModalLoaderShow(true)
            let email = response.profileObj.email
            let nombre = response.profileObj.givenName
            let apellido = response.profileObj.familyName
            getEmailUser({email, nombre, apellido})
            setRedirect(true)
            setModalLoaderShow(false)
        } 

        const failedResponseGoogle = () => {
            setRedirect(false)
        }
        
        
        const checkUser = async () => {

            setModalLoaderShow(true)
    
            const db = getFirestore();
            const dbQuery = db
                .collection("usuarios")
                .where("email", "==", email);
            dbQuery
                .get()
                .then((res) => {
                    if (res.docs.length > 0) {
                        let userResult = res.docs.map(item => ( {id: item.id, ...item.data()} ) )
                        let userMap = userResult.find(usR => usR.email === email)
                        
                        bcrypt.compare(pass, userMap.psw, function(err, result) {
                          if(result) {
                            let email = userMap.email
                            let nombre = userMap.nombre
                            let apellido = userMap.apellido
                            getEmailUser({email, nombre, apellido})
                            setRedirect(true)
                            setModalLoaderShow(false)
                          }
                          else {
                            setInvalidData(true)
                            setModalLoaderShow(false)
                            }
                        });
    
                    }
                    else {
                        setInvalidData(true)
                        setModalLoaderShow(false)
                        }
                })
                .catch((err) => console.log(err));
            
        };

        
    
    return(

        <div className='container'>

            { redirect ? <ExtraDataAccount/> :
                    
                    <div className='fullScreen bg-light h-100 align-items-center d-flex justify-content-center'>

                        <div className='bg-white rounded p-5 justify-content-center'>
                            <div className='row'>
                               <h3 className='mb-5 col titularForm'>Por favor, ingresá con tu cuenta de Google</h3>
                            </div>
                            <div className='row justify-content-center'>
                            <GoogleLogin
                            className='buttonGoogle'
                                clientId="585823760287-mfi10vkpv7dbmsrpjqa1qsqu05p5as3n.apps.googleusercontent.com"
                                render={renderProps => (
                                        <Image className='googleButton' onClick={renderProps.onClick} src={googleButton} fluid />
                                )}
                                buttonText="Acceder con Google"
                                onSuccess={responseGoogle}
                                onFailure={failedResponseGoogle}
                                cookiePolicy={'single_host_origin'}
                                isSignedIn={true}
                            />
                            </div> 
                            <div className='row justify-content-center mt-5'>
                               <div className='text-center'>Accedé con otra cuenta</div>
                               <div className='col-8 mt-2 d-flex justify-content-center flex-column'>
                                    <TextField
                                        id="inputEmail"
                                        name="inputEmail"
                                        label="Correo electrónico"
                                        variant="standard"
                                        fullWidth
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                     <TextField
                                        id="inputPass"
                                        name="inputPass"
                                        label="Contraseña"
                                        variant="standard"
                                        fullWidth
                                        onChange={(e) => setPass(e.target.value)}
                                        type="password"
                                        className='mt-3'
                                    />
                                    <Button disabled={email.trim() === "" || pass.trim() === ""} className="mt-4" onClick={checkUser} variant="outlined">Enviar</Button>
                                    {invalidData && <div className='text-danger'>Usuario y/o contraseña incorrectos</div>}
                               </div>
                            </div>
                        </div>
                            
                    </div>   
                        
            } 

            {modalLoaderShow && <div className="fullScreen bg-light h-100 align-items-center d-flex justify-content-center"><SpinnerDiamond size={100} color='#5b6c7e' /></div>}
                

       </div>

    )
}