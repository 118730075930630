import Image from 'react-bootstrap/Image'
import imagenSticker from '../images/sticker.svg'
import ListGroup from 'react-bootstrap/ListGroup'
import logoMl from '../images/ml-logo.png'

export const Sticker = () => {


    return(
        <div className='bg-white pb-5'>
            <div id="sticker" className='spacer'></div>
                <div className="container contenedor__sticker">
                        <h1 className='titularSticker fw-bold'>Conseguí el sticker para pegar!</h1>
                        <p className='parrafoSticker'>También tenés la opción de adquirir el sticker impreso listo para pegar en tu bici!</p>
                        <div className="imagenSticker"><Image src={imagenSticker} fluid /></div>
                        <div className="textoSticker">
                            <ListGroup as="ol" numbered>
                                <ListGroup.Item as="li">Pegá el sticker en tu bici y escaneá el código QR tu teléfono.</ListGroup.Item>
                                <ListGroup.Item as="li">Ingresá con tu usuario. Si no tenés uno crealo gratis.</ListGroup.Item>
                                <ListGroup.Item as="li">Completá los datos de tu bici.</ListGroup.Item>
                            </ListGroup>
                            <div className='marcaLider mt-5'>Conseguilo en <a rel="noreferrer" target="_blank" href='https://articulo.mercadolibre.com.ar/MLA-751266673-sticker-de-identificacion-de-bicicletas-pack-x-5-u-_JM?searchVariation=28245978573#searchVariation=28245978573&position=1&search_layout=stack&type=item&tracking_id=3a8e2e27-cb31-461d-8d70-89edfc29fdec'><Image className='logoMl mx-2' src={logoMl} fluid /></a></div>
                        </div>
                </div>
        </div>
      
    )
}