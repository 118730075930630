import { Container, Navbar, Nav} from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import { HashLink as Link } from 'react-router-hash-link';
import logoNav from'../images/logoNav.png';

export const NavBar = () => {
    return(

        <Navbar className='nav' variant='dark' expand="lg">
          <Container>
            <Navbar.Brand as={Link} to="/"><Image src={logoNav} fluid /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto h6">
                <Navbar.Text className='mx-2' as={Link} to="/#servicio">Sobre el servicio</Navbar.Text>
                <Navbar.Text className='mx-2' as={Link} to="/#aliados">Aliados</Navbar.Text>
                <Navbar.Text className='mx-2' as={Link} to="/#sticker">Conseguí el sticker</Navbar.Text>
                <Navbar.Text className='mx-2' as={Link} to="/#faq">FAQ</Navbar.Text>
              </Nav>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end">
              <Nav.Link className='border border-white rounded-pill text-white mx-2 botonPanel' as={Link} to="/usr/newAccount">Mis bicis</Nav.Link>
            </Navbar.Collapse>
          </Container>
        </Navbar>

    )
}