import { useEffect, useState } from "react"
import Image from 'react-bootstrap/Image'
import { useParams } from "react-router-dom"
import { getFirestore } from "../services/getFirestore"
import {QRCodeCanvas} from 'qrcode.react'
import JsPDF from 'jspdf'
import baseBR from '../images/baseBR.png'
import html2canvas from 'html2canvas'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import NewBikeForm from "./NewBikeForm"
import { Grid, Container, Paper } from '@mui/material'
import { SpinnerDiamond } from "spinners-react"

export const BikeDetails = ({bikeId}) => {
    
    const [infoBike, setInfoBike] = useState([])
    const [modalLoaderShow, setModalLoaderShow] = useState(true)
    const [modalEditInfo, setModalEditInfo] = useState(false)
    const [imgAlert, setImgAlert] = useState('')
    const [image, setImage ] = useState({})
    const [url, setUrl ] = useState("")
    const [modalDelete, setModalDelete] = useState(false)
    const [deletedBike, setDeletedBike] = useState(false)
    const [modalStolen, setModalStolen] = useState(false)
    const [isStolen, setIsStolen] = useState(false)
    const [modalRecovered, setModalRecovered] = useState(false)
    const [modalQR, setModalQR] = useState(false)
    
    const {idBike} = useParams()
    
    
    useEffect(() => {
        
        const db = getFirestore()
        const dbQuery = db.collection('bicicletas').doc(bikeId ? bikeId : idBike)
        dbQuery.get()
        .then(res => setInfoBike({id: res.id, ...res.data()}))
        .finally(()=> setModalLoaderShow(false))
        .catch(err => console.log(err))
 
    }, [idBike, bikeId])
    
    
    
    useEffect(() => {
        
        infoBike.robada && setIsStolen(true)
        setUrl(infoBike.foto)
        
   }, [infoBike])



    const generatePDF = () => {

        const input = document.querySelector('#report')

        html2canvas(input, {scale: 4, dpi: 300})
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new JsPDF({
                    orientation: 'landscape',
                    unit: 'mm',
                    format: [50, 50]
                })
                const imgProps= pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth()/1.5;
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
                pdf.addImage(imgData, 'PNG', 9, 9, pdfWidth, pdfHeight)
                pdf.save(`BiciRegistro_${infoBike.id}.pdf`)
                
            })
    }

    const uploadBike = (e) => {
        e.preventDefault()
        let marca = e.target.inputContactBrand.value
        let serie = e.target.inputContactSerie.value
        let modelo = e.target.inputContactModel.value
        let descripcion = e.target.inputContactDescripcion.value

        const data = new FormData()
        
        if(image.size > 1048576) setImgAlert('Por favor, elegí una imagen de hasta 1 mb de tamaño.')
        else if(image.type && !image.type.includes('image')) setImgAlert('Por favor, elegí una imagen')
        else {   
            setModalLoaderShow(true)
            data.append("file", image)
            data.append("upload_preset", "biciregistro")
            data.append("cloud_name","biciregistro")
            fetch("https://api.cloudinary.com/v1_1/biciregistro/image/upload",{
                method:"post",
                body: data
            })
            .then(resp => resp.json())
            .then(data => {

                
                if(!data.url) data.url = infoBike.foto
               
                let bike = {
                    marca: marca,
                    serie: serie,
                    modelo: modelo,
                    foto: data.url,
                    descripcion: descripcion
                }
    
                const dbQuery = getFirestore()
                dbQuery.collection('bicicletas').doc(idBike).update(bike)
                    .then(() => setInfoBike(bike))
                    .finally(() => {
                        setModalLoaderShow(false)
                        setModalEditInfo(false)
                    })
                    .catch(err => console.log(err))
            })
            
            .catch(err => console.log(err))

        }
    }

    const deleteBike = () => {

        setModalLoaderShow(true)
        const dbQuery = getFirestore()
        dbQuery.collection('bicicletas').doc(idBike).delete()
            .then(() => {
                setDeletedBike(true)
                setModalDelete(false)
            })
            .finally(() => setModalLoaderShow(false))
            .catch(err => console.log(err))
    }


    const stolenBike = () => {

        setModalLoaderShow(true)

        const dbQuery = getFirestore()
        dbQuery.collection('bicicletas').doc(idBike).update({robada: true})
            .then(() => setIsStolen(true))
            .then(() => setModalStolen(false))
            .then(() => setModalLoaderShow(false))
            .then(() => document.getElementById('modifyButton').setAttribute('disabled', 'true'))
            .catch(err => console.log(err))
    }

    const recoveredBike = () => {

        document.getElementById('modifyButton').setAttribute('disabled', 'true')

        setModalLoaderShow(true)
        const dbQuery = getFirestore()
        dbQuery.collection('bicicletas').doc(idBike).update({robada: false})
            .then(() => setIsStolen(false))
            .then(() => setModalRecovered(false))
            .then(() => setModalLoaderShow(false))
            .then(() => document.getElementById('modifyButton').removeAttribute('disabled'))
            .catch(err => console.log(err))
    }

    
    
    

    return (
        <>

            { modalLoaderShow ? <div className="fullScreen bg-light h-100 align-items-center d-flex justify-content-center"><SpinnerDiamond size={100} color='#5b6c7e' /></div> :
                (

                    deletedBike ? window.location.replace("../../usr/newAccount") :

                        <div className="marginBoxBikeDetail">
                            

                                    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                                        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                                    

                                            <h2 className="text-center titularForm mb-2"><b>{infoBike.marca}</b> {infoBike.modelo}</h2>

                                            {isStolen && <div className="alert alert-danger">Bicicleta reportada como robada.</div>}

                                            {!bikeId &&

                                            <div className="d-flex justify-content-center mb-3">
                                                <button id="modifyButton" onClick={() => setModalEditInfo(true)} className="bikeDetail__modify text-center p-2 border d-inline rounded bi bi-pencil-square mt-2 mx-1" disabled={isStolen ? true : false}> Editar</button>
                                                <button onClick={() => setModalDelete(true)} className="bikeDetail__modify text-center p-2 border d-inline rounded bi bi-trash mt-2 mx-1"> Borrar</button>
                                                {isStolen ? <button onClick={() => setModalRecovered(true)} className="bikeDetail__modify text-center p-2 border d-inline rounded bi bi-check-circle mt-2 mx-1"> Ya la recuperé</button> : <button onClick={() => setModalStolen(true)} className="bikeDetail__modify text-center p-2 border d-inline rounded bi bi-exclamation-octagon mt-2 mx-1"> Robada</button>}
                                                <button onClick={() => setModalQR(true)} className="bikeDetail__modify text-center p-2 border d-inline rounded bi bi-qr-code mt-2 mx-1"> Código QR</button>
                                            </div>}


                                            <Grid item xs={12} sm={6}>
                                                <Image className="bikeDetail__foto__itself flex-grow-1" fluid rounded src={infoBike.foto}/>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                            <div className="flex-grow-1 w-100">
                                                <div className="border-bottom m-3 pb-3">
                                                    <div className="bikeDetail__type">Marca</div>
                                                    <div>{infoBike.marca}</div>
                                                </div>
                                                <div className="border-bottom m-3 pb-3">
                                                    <div className="bikeDetail__type">Modelo</div>
                                                    <div>{infoBike.modelo}</div>
                                                </div>
                                                <div className="border-bottom m-3 pb-3">
                                                    <div className="bikeDetail__type">N° de Serie</div>
                                                    <div>{infoBike.serie}</div>
                                                </div>
                                                <div className="m-3 pb-3">
                                                    <div className="bikeDetail__type">Descripción</div>
                                                    <div>{infoBike.descripcion}</div>
                                                </div>
                                            </div>
                                            </Grid>
                                        </Paper>
                                    </Container>


                            <Modal
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                show={modalEditInfo}
                                onHide={() => setModalEditInfo(false)}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Editar datos</Modal.Title>
                                </Modal.Header>
                                    <Modal.Body>
                                        <NewBikeForm bikeData={infoBike} bikeAction={uploadBike} imgAlert={imgAlert} url={url} imageChange= {io => setImage(io)}/>
                                    </Modal.Body>
                            </Modal>

                            <Modal
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                show={modalDelete}
                                onHide={() => setModalDelete(false)}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Borrar registro</Modal.Title>
                                </Modal.Header>
                                    <Modal.Body>
                                        Estas seguro de borrar el registro de la bici?. No lo vas a poder recuperar.
                                    </Modal.Body>

                                    <Modal.Footer>
                                        <Button className="btn-danger" onClick={() => deleteBike()}>Borrar</Button>
                                    </Modal.Footer>

                            </Modal>

                            <Modal
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                show={modalStolen}
                                onHide={() => setModalStolen(false)}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Bicicleta Robada</Modal.Title>
                                </Modal.Header>
                                    <Modal.Body>
                                        Reportar bicicleta como robada?. Esto generara un alerta al obtener la info de la bici.
                                    </Modal.Body>

                                    <Modal.Footer>
                                        <Button className="btn-warning" onClick={() => stolenBike()}>Reportar como robada</Button>
                                    </Modal.Footer>

                            </Modal>

                            <Modal
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                show={modalRecovered}
                                onHide={() => setModalRecovered(false)}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Bicicleta Recuperada</Modal.Title>
                                </Modal.Header>
                                    <Modal.Body>
                                        Recuperaste tu bici?. Nos alegra oir eso!, sacaremos el alerta en la info de la bici.
                                    </Modal.Body>

                                    <Modal.Footer>
                                        <Button className="btn-success" onClick={() => recoveredBike()}>Confirmar</Button>
                                    </Modal.Footer>

                            </Modal>

                            <Modal
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                show={modalQR}
                                onHide={() => setModalQR(false)}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Código QR de la bici</Modal.Title>
                                </Modal.Header>
                                    <Modal.Body>
                                            <h4 className="text-center titularForm mb-3"><b>{infoBike.marca}</b> {infoBike.modelo}</h4>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div id="report" className="m-2">
                                                    <div className="bikeDetail__wraperQR">
                                                    <div className="bikeDetail__wrapperQR__Base"><Image fluid src={baseBR}/></div>
                                                    <div className="bikeDetail__wraperQR__QR"><QRCodeCanvas size={75} value={`http://wwww.biciregistro.com.ar/${infoBike.id}`} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                    </Modal.Body>

                                    <Modal.Footer className="d-flex justify-content-center">
                                        <Button className="bi bi-file-earmark-arrow-down" onClick={() => generatePDF()}> Descargar</Button>
                                    </Modal.Footer>

                            </Modal>

                        </div>

                )
                        
        }
        </>
    )
}