import { createContext, useContext, useState } from "react"

const CardContext = createContext()
export const useCardContext = () => useContext(CardContext)

export const CardContextProvider = ({children}) => {

    const [emailUser, setEmailUser] = useState('')
    const [bikeUrl, setBikeUrl] = useState('')
    const [imgUrl, setImgUrl] = useState({})
    const [isQR, setIsQR] = useState(false)

    const getEmailUser = (item) => setEmailUser(item)

    const getBikeUrl = (item) => setBikeUrl(item)

    const getImgUrl = (item) => setImgUrl(item)

    const getQRState = data => setIsQR(data)



    return (
        <CardContext.Provider value={{getEmailUser, emailUser, getBikeUrl, bikeUrl, getImgUrl, imgUrl, getQRState, isQR}}>

            {children}

        </CardContext.Provider>
    )
}