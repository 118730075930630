import React from "react"
import { useState, useEffect } from 'react'
import { getFirestore } from '../services/getFirestore'
import { BikePanel } from "./BikePanel"
import { useCardContext } from "../context/CardContext"
import { ExtraDataForm } from "./ExtraDataForm"
import NewBikeForm from "./NewBikeForm"
import { useParams } from "react-router-dom"
import { SpinnerDiamond } from 'spinners-react'

export const ExtraDataAccount = () => {

    
    const [redirect, setRedirect] = useState(false) 
    const [modalLoaderShow, setModalLoaderShow] = useState(true)
    const [image, setImage ] = useState("")
    const [imgAlert, setImgAlert] = useState('')

    const {emailUser, isQR} = useCardContext()
    const {idBike} = useParams()
    
    useEffect(() => {

        const db = getFirestore()
        const dbQuery = db.collection('usuarios').where('email', '==', emailUser.email)
        dbQuery.get()
            .then(res => {
                if(res.docs.length > 0) {
                    setRedirect(true)
                }
            })
            .catch(err => console.log(err))
            .finally(()=> {
                setModalLoaderShow(false)
            })
            

    },[emailUser.email])


    
    const uploadBike = (e) => {
        e.preventDefault()
        let marca = e.target.inputContactBrand.value
        let serie = e.target.inputContactSerie.value
        let modelo = e.target.inputContactModel.value
        let descripcion = e.target.inputContactDescripcion.value
        
        const data = new FormData()
        if(image.size > 1048576) setImgAlert('Por favor, elegí una imagen de hasta 1 mb de tamaño.')
        else if(image.type && !image.type.includes('image')) setImgAlert('Por favor, elegí una imagen')
        else {
            setModalLoaderShow(true)
            data.append("file", image)
            data.append("upload_preset", "biciregistro")
            data.append("cloud_name","biciregistro")
            fetch("https://api.cloudinary.com/v1_1/biciregistro/image/upload",{
                method:"post",
                body: data
            })
            .then(resp => resp.json())
            .then(data => {

               
                let bike = {
                    marca: marca,
                    serie: serie,
                    modelo: modelo,
                    foto: data.url,
                    descripcion: descripcion,
                    usuario: emailUser.email,
                    blank: false
                }
    
                const dbQuery = getFirestore()
                dbQuery.collection('bicicletas').doc(idBike).update(bike)
                    .then(() => window.location.reload())
                    .finally(() => setModalLoaderShow(false))
                    .catch(err => console.log(err))
            })
            
            .catch(err => console.log(err))
        }
    }
   

    return(

    <>
        { modalLoaderShow ? <div className="fullScreen bg-light h-100 align-items-center d-flex justify-content-center"><SpinnerDiamond size={100} color='#5b6c7e' /></div> :
            (
                redirect ? isQR ? <div className="marginBox"><NewBikeForm newBikeQR = {uploadBike} imgAlert={imgAlert} imageChange= {io => setImage(io)}/></div> : <BikePanel/> : 
                    <div className="marginBox">

                        <ExtraDataForm newBikeQR = {uploadBike}/>
                                
                    </div>
            )
                    
        }
    </>
    )
}
