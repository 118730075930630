import { useParams } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { getFirestore } from "../services/getFirestore"
import { BikeDetails } from "./BikeDetails"
import { Grid, Container, Paper } from '@mui/material'
import { NewAccount } from "./NewAccount"
import { useCardContext } from "../context/CardContext"

export const InfoBike = () => {

    const {idBike} = useParams()

    const [noResult, setNoResult] = useState(false)
    const [user, setUser] = useState([])
    const [blank, setBlank] = useState(false)

    const {getQRState} = useCardContext()

    useEffect(() => {

        getQRState(true)

        const db = getFirestore()
        const dbQuery = db.collection('bicicletas').doc(idBike)
        dbQuery.get()
            .then(res => {

                if (res.data() !== undefined) {

                    res.data().blank && setBlank(true)

                    const userQuery = db.collection('usuarios').where('email', '==', res.data().usuario)
                    userQuery.get()
                        .then(resp => {
                            let userResult = resp.docs.map(item => ( {id: item.id, ...item.data()} ) )
                            setUser(userResult.find(usR => usR.email === res.data().usuario))
                    })
                        .catch(err => console.log(err))  

                }

                else setNoResult(true)
    
            })
            .catch(err => console.log(err))

    }, [idBike, getQRState])

    
    return (
        <>

            {blank ? <NewAccount/> : 

            noResult ?

            window.location.replace("./") :
            
            <>
                <BikeDetails bikeId = {idBike}/>

                <Container component="main" maxWidth="sm" sx={{ mb: 15 }}>
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>

                        <React.Fragment>
                            <Grid container spacing={3}>

                                <Grid item xs={12} sm={6}>
                                    <div className="bikeDetail__type">Propietario</div>
                                    <div>{user.nombre} {user.apellido}</div>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <div className="bikeDetail__type">DNI</div>
                                    <div>{user.dni}</div>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <div className="bikeDetail__type">Grupo sanguíneo</div>
                                    <div>{user.grupoSanguineo}</div>
                                </Grid>
                                    
                                <Grid item xs={12} sm={6}>
                                    <div className="bikeDetail__type">Contacto de emergencia</div>
                                    <div>{user.trustedPerson} (Tel.: {user.trustedNumber})</div>
                                </Grid>
                                
                            </Grid>
                        </React.Fragment>

                    </Paper>
                </Container>

            </>

            }
        </>
    )
}