import Accordion from 'react-bootstrap/Accordion'

export const Faq = () => {
    return(

        <>
            <div id="faq" className='spacer'></div>

            <div className='bg-light p-5 contenedorFaq'>
                <div className='container'>
                    <h1 className='titularFaq fw-bold mb-5'>Preguntas Frecuentes (FAQ)</h1>
                    <Accordion>

                    <Accordion.Item eventKey="0">
                    <Accordion.Header>¿Cómo se crea un usuario y que costo tiene?</Accordion.Header>
                    <Accordion.Body>
                        La creación de un usuario en <b>BiciRegistro</b> no tiene costo alguno, ni mantención mensual, solo se debe cargar los datos solicitados, apellido y nombre, grupo sanguíneo y teléfono de una persona de confianza como contacto de emergencia. <b>Es importante que el grupo sanguíneo y del contacto de emergencia sean datos verídicos.</b>
                    </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                    <Accordion.Header>¿Cómo cargar los datos de la bicicleta?</Accordion.Header>
                    <Accordion.Body>
                        Una vez creado el usuario podés ingresar a tu panel de control y agregar hasta <b>5 bicicletas que sean de tu propiedad.</b> Vas a necesitar los datos de marca, modelo, número de serie del cuadro (en la mayoría de las bicicletas la encontrarás debajo de la pedalera, en caso de no encontrarlo consulta en la bicicletería donde realizaste la compra), un link online con la foto en donde se vea toda la bicicleta, la descripción y colocá los componentes que la hacen única.
                    </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                    <Accordion.Header>¿Cuántas bicicletas puedo registrar?</Accordion.Header>
                    <Accordion.Body>
                        Podés registrar de forma gratuita un máximo de <b>5 bicicletas.</b> Podés registrar la cantidad de bicicletas que tengas, siempre y cuando sean de tu propiedad. Todas las bicicletas que tengas registradas se verán en tu panel de control.
                    </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                    <Accordion.Header>¿Qué hacer en caso de robo?</Accordion.Header>
                    <Accordion.Body>
                        Ingresar al panel de control (desde la app o la web de BiciRegistro) y seleccionar la opción <b>ROBADA</b>, de esa forma informas a cualquier persona que lea el código QR (o busque en la web el número de serie del cuadro) que la bicicleta es robada. Desde <b>BiciRegistro</b> vamos informar a las bicicleterías y publicar en nuestras redes sobre lo sucedido.
                        Es importante que como sociedad tomemos conciencia de las implicancias del robo de bicicletas, y por consiguiente no alimentar el mercado, <b>NO COMPRANDO BICICLETAS ROBADAS</b> y denunciando cuando se tenga conocimiento de que se está vendiendo un rodado hurtado. Por ello, exigile a tu bicicletero amigo/de confianza cuando compres tú bicicleta, que la misma salga del establecimiento con el certificado de confianza y el código QR.
                    </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                    <Accordion.Header>¿Cómo modifico los datos de mi bicicleta que ya cargué?</Accordion.Header>
                    <Accordion.Body>
                        En el caso de que se le realicen mejoras* y/o cambios de componentes, se puede agregar en la descripción del rodado. Es aconsejable realizar al mismo momento el cambio de fotografía para dar cuenta de la actualización de los componentes. Es muy importante que esta información siempre se encuentre actualizada. Para ello solo deberá ingresar al panel de control (desde la app o la web de BiciRegistro) y seleccionar la opción <b>MODIFICAR.</b>
                        * Entiéndase por modificaciones al cambios de componentes en una bicicleta, siempre y cuando la misma no modifique el cuadro y el nro de serie de la misma. En caso de que se realice cambio de cuadro, se deberá proceder al registro de la bicicleta con un registro diferente.
                    </Accordion.Body>
                    </Accordion.Item>

                    {/* <Accordion.Item eventKey="5">
                    <Accordion.Header>¿Cómo transfiero mi bicicleta a otra persona?</Accordion.Header>
                    <Accordion.Body>
                        Cuando el titular del registro realice la venta de su bicicleta* deberá ingresar al panel de control (desde la app o la web de BiciRegistro) y seleccionar la opción <b>TRANSFERENCIA</b>, para ello es necesario que el nuevo propietario de la bicicleta tenga una cuenta en <b>BiciRegistro</b> y acepte la transferencia del rodado.
                        * Entiéndase por bicicleta a todo el rodado completo, o en su defecto al cuadro con el nro de serie del mismo.
                    </Accordion.Body>
                    </Accordion.Item> */}

                    <Accordion.Item eventKey="6">
                    <Accordion.Header>¿Cómo descargo la APP, tiene algún costo?</Accordion.Header>
                    <Accordion.Body>
                        Podés descargar la APP de BiciRegistro <a rel="noreferrer" target="_blank" href='https://play.google.com/store/apps/details?id=br.com.ar.biciregistro'>desde aquí</a>. Tanto la descarga como su uso son gratuitos.
                    </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="7">
                    <Accordion.Header>¿Necesito la APP para el funcionamiento de BiciRegistro?</Accordion.Header>
                    <Accordion.Body>
                        No, el sistema funciona también desde la web, no obstante hay funcionalidades que sólo están disponibles desde la APP.
                    </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="8">
                    <Accordion.Header>¿Cómo vendo mi bicicleta?</Accordion.Header>
                    <Accordion.Body>
                        En tu panel de control podés seleccionar la opción <b>PUBLICAR EN LA TIENDA</b> y definir el valor del misma, los interesados en tu bici se comunicarán con vos con tus datos de contacto. Este servicio no tiene ningún tipo de comisión para ambas partes.
                    </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="9">
                    <Accordion.Header>¿Cómo veo el mapa en la app?</Accordion.Header>
                    <Accordion.Body>
                        Si no podes ver el mapa de geolocalización es probable que debas conceder el premiso de acceso de la ubicación a la APP. Para ello, en tu teléfono debes ingresar en <b>Configuración / APP y Notificaciones</b> y activar la función permiso de ubicación para la aplicación.
                    </Accordion.Body>
                    </Accordion.Item>

                </Accordion>
            </div>
        </div>

      </>
      
    )
}