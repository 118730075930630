import { Terms } from "./Terms"
import Button from 'react-bootstrap/Button'
import { useState } from 'react'

export const Footer = () => {

    const [modalShow, setModalShow] = useState(false) 

    return(

    <div className="containerFooter">
        <div className="container footer p-1 pt-2">
            <p className="m-2">&copy; BiciRegistro</p>
            <p className="m-2">&#124;</p>
            <p className="m-2"><Button className="terms" id="termsButton" variant="link" onClick={() => setModalShow(true)}>Ver términos y condiciones</Button></p>
        </div>
        <Terms show={modalShow} onHide={() => setModalShow(false)}/>
    </div>
      
    )
}