// import firebase from "firebase/app"
// import 'firebase/firestore'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

 const firebaseConfig = {
    apiKey: "AIzaSyDKRc8ZIrvuOm9guPLyg1WstGXhQE9jqeQ",
    authDomain: "biciregistro-5c030.firebaseapp.com",
    projectId: "biciregistro-5c030",
    storageBucket: "biciregistro-5c030.appspot.com",
    messagingSenderId: "585823760287",
    appId: "1:585823760287:web:e543ba3b3245b5776817aa"
  };

  const app = firebase.initializeApp(firebaseConfig)

export function getFirestore(){    
    return firebase.firestore(app)
}