import Image from 'react-bootstrap/Image'
import imagenServicio from '../images/servicio.svg'
import thiefIcon from '../images/thiefIcon.svg'
import paramedicIcon from '../images/paramedicIcon.svg'

export const Servicio = () => {

     

    return(

     <div className='bg-white'>
          <div id="servicio" className='spacer'></div>
               <div className="container contenedor">
                    <h1 className='titularServicio fw-bold'>Cómo funciona el servicio?</h1>
                    <div className="texto">
                         <p>BiciRegistro es un sistema de identificación de bicicletas que funciona mediante la creación de un código QR de identificación, con el que se puede acceder de forma rápida a la información de cada bicicleta y su propietario.</p>
                         <p>El sistema funciona como una base de datos de rodados, en el que cada ciclista puede registrar la cantidad de bicicletas que posea.</p>
                         <p>Además, si formas parte de BiciRegistro, en caso de robo de tu bicicleta se dará aviso a una amplia red de bicicleterías a lo largo de todo el país; aumentando así las posibilidades de recuperarla.</p>
                    </div>
                    <div className="imagen"><Image className='imagenServicio' src={imagenServicio} fluid /></div>

                    <div className="serviceIcons thief mt-5">
                         <div><Image className='serviceIcon' src={thiefIcon} fluid /></div>
                         <h3 className='tituloIcon fw-bold'>Si te robaron</h3>
                         <p className='iconText'>Podés marcarla como ROBADA, así y cualquier bicicletería, servicio policial y/o persona que lea el código QR podrá avisarte de su paradero.</p>     
                    </div>
                    <div className="serviceIcons paramedic mt-5">
                         <div><Image className='serviceIcon' src={paramedicIcon} fluid /></div>
                         <h3 className='tituloIcon fw-bold'>Si te accidentaste</h3>
                         <p className='iconText'>Con tus datos de contacto de emergencia y grupo sanguíneo, tenés una herramienta más para brindarle a los servicios de emergencia y/o quien acuda a ayudarte.</p>
                    </div>
          
               </div>  
     </div>
      
    )
}