import React from "react"
import NewBikeForm from "./NewBikeForm"

export const NewBike = () => {

    

    return(

        <div className="marginBox">
            
                    <NewBikeForm/> 
            
        </div>

    )
}