import { Card } from "react-bootstrap"
import { Link } from "react-router-dom"

export const CardNewbike = () => {
    return (
        
        <Card className="panelCard m-3">
            <Card.Body className="d-flex align-items-center justify-content-center">
                <Link to='/usr/newBike'>
                    <Card.Text>
                            <span className="d-flex align-items-center justify-content-center plusCard border border-secondary text-secondary rounded-circle">+</span>
                    </Card.Text>
                </Link>
            </Card.Body>
        </Card>
    
    )
}