import { Link } from "react-router-dom"
import { Card } from "react-bootstrap"

export const CardBike = ({item}) => {


    return (
        

            <Card className="panelCard m-3 p-0">
                <Link to={`/usr/bikeDetails/${item.id}`}>
                    <Card.Img variant="top" src={item.foto} />
                    <Card.Body>
                        <Card.Title className="bikeCard__title"><b>{item.marca}</b> {item.modelo}</Card.Title>
                    </Card.Body>
                </Link>
            </Card>

        
    )
} 