import Image from 'react-bootstrap/Image'
import assured from '../images/aliados/assured.png'
import biciup from '../images/aliados/biciup.png'
import cadencia from '../images/aliados/cadencia.png'
import federacionpatronal from '../images/aliados/federacionpatronal.png'
import magalicilia from '../images/aliados/magalicilia.png'
import megabike from '../images/aliados/megabike.png'
import movetegustate from '../images/aliados/movetegustate.png'
import mundobike from '../images/aliados/mundobike.png'
import nodari from '../images/aliados/nodari.png'
import ojoclinico from '../images/aliados/ojoclinico.png'
import palitomacias from '../images/aliados/palitomacias.png'
import rallymbtpilar from '../images/aliados/rallymtbpilar.png'
import regiopinon from '../images/aliados/regiopinon.png'
import rodadospaternal from '../images/aliados/rodadospaternal.png'
import sabike from '../images/aliados/sabike.png'
import universo from '../images/aliados/universo.png'

export const Aliados = () => {
    return(

    <>
        <div id="aliados" className='spacer'></div>

       <div className='bg-light aliados'>
            <div className='container p-5'>
                <h1 className='titularAliados fw-bold mb-5'>Estos aliados trabajan con BiciRegistro</h1>
                <div className='container__aliados'>

                <div className='logoWrap m-1'>
                    <Image className='logoAliados' src={assured} fluid />
                    <p className='logoText rounded'><a rel="noreferrer" target="_blank" href='https://www.instagram.com/facundobalangero'>Assured <i className="bi bi-box-arrow-up-right mx-1"></i></a></p>
                </div>

                <div className='logoWrap m-1'>
                    <Image className='logoAliados' src={biciup} fluid />
                    <p className='logoText rounded'><a rel="noreferrer" target="_blank" href='https://www.biciup.com.ar/'>BiciUp <i className="bi bi-box-arrow-up-right mx-1"></i></a></p>
                </div>

                <div className='logoWrap m-1'>
                    <Image className='logoAliados' src={cadencia} fluid />
                    <p className='logoText rounded'><a rel="noreferrer" target="_blank" href='https://cadencia.com.ar/'>Cadencia <i className="bi bi-box-arrow-up-right mx-1"></i></a></p>
                </div>

                <div className='logoWrap m-1'>
                    <Image className='logoAliados' src={federacionpatronal} fluid />
                    <p className='logoText rounded'><a rel="noreferrer" target="_blank" href='https://www.fedpat.com.ar/'>Federación Patronal <i className="bi bi-box-arrow-up-right mx-1"></i></a></p>
                </div>

                <div className='logoWrap m-1'>
                    <Image className='logoAliados' src={magalicilia} fluid />
                    <p className='logoText rounded'><a rel="noreferrer" target="_blank" href='https://www.facebook.com/MagaliSeguros'>Magali Cilia <i className="bi bi-box-arrow-up-right mx-1"></i></a></p>
                </div>

                <div className='logoWrap m-1'>
                    <Image className='logoAliados' src={megabike} fluid />
                    <p className='logoText rounded'><a rel="noreferrer" target="_blank" href='https://www.instagram.com/megabikecenter/'>Mega Bike Center <i className="bi bi-box-arrow-up-right mx-1"></i></a></p>
                </div>

                <div className='logoWrap m-1'>
                    <Image className='logoAliados' src={movetegustate} fluid />
                    <p className='logoText rounded'><a rel="noreferrer" target="_blank" href='http://www.movetegustate.com/'>Movete Gustate <i className="bi bi-box-arrow-up-right mx-1"></i></a></p>
                </div>

                <div className='logoWrap m-1'>
                    <Image className='logoAliados' src={mundobike} fluid />
                    <p className='logoText rounded'><a rel="noreferrer" target="_blank" href='https://www.instagram.com/mundobikeargentina/'>Mundo Bike Argentina <i className="bi bi-box-arrow-up-right mx-1"></i></a></p>
                </div>

                <div className='logoWrap m-1'>
                    <Image className='logoAliados' src={nodari} fluid />
                    <p className='logoText rounded'><a rel="noreferrer" target="_blank" href='https://www.nodari.com.ar/'>Nodari Cycles <i className="bi bi-box-arrow-up-right mx-1"></i></a></p>
                </div>

                <div className='logoWrap m-1'>
                    <Image className='logoAliados' src={ojoclinico} fluid />
                    <p className='logoText rounded'><a rel="noreferrer" target="_blank" href='https://www.instagram.com/ojo_clinico_bike'>Ojo Clínico <i className="bi bi-box-arrow-up-right mx-1"></i></a></p>
                </div>

                <div className='logoWrap m-1'>
                    <Image className='logoAliados' src={palitomacias} fluid />
                    <p className='logoText rounded'><a rel="noreferrer" target="_blank" href='https://www.instagram.com/palitomaciasbikestore/'>Palito Macías <i className="bi bi-box-arrow-up-right mx-1"></i></a></p>
                </div>

                <div className='logoWrap m-1'>
                    <Image className='logoAliados' src={rallymbtpilar} fluid />
                    <p className='logoText rounded'><a rel="noreferrer" target="_blank" href='http://www.rallymtbpilar.com.ar/'>Rally MTB Pilar <i className="bi bi-box-arrow-up-right mx-1"></i></a></p>
                </div>

                <div className='logoWrap m-1'>
                    <Image className='logoAliados' src={regiopinon} fluid />
                    <p className='logoText rounded'><a rel="noreferrer" target="_blank" href='https://regiopinion.wixsite.com/tubicisosvos'>Regio Piñón <i className="bi bi-box-arrow-up-right mx-1"></i></a></p>
                </div>

                <div className='logoWrap m-1'>
                    <Image className='logoAliados' src={rodadospaternal} fluid />
                    <p className='logoText rounded'><a rel="noreferrer" target="_blank" href='https://www.rodadospaternal.com.ar/'>Rodados Paternal <i className="bi bi-box-arrow-up-right mx-1"></i></a></p>
                </div>

                <div className='logoWrap m-1'>
                    <Image className='logoAliados' src={sabike} fluid />
                    <p className='logoText rounded'><a rel="noreferrer" target="_blank" href='https://www.instagram.com/sabike.ar/'>SaBike <i className="bi bi-box-arrow-up-right mx-1"></i></a></p>
                </div>

                <div className='logoWrap m-1'>
                    <Image className='logoAliados' src={universo} fluid />
                    <p className='logoText rounded'><a rel="noreferrer" target="_blank" href='https://www.rodadosuniverso.com.ar/'>Rodados Universo <i className="bi bi-box-arrow-up-right mx-1"></i></a></p>
                </div>

                </div>
            </div>
       </div>
    </>
    )
}