import * as React from 'react';
import { useEffect, useState } from "react"
import { getFirestore } from '../services/getFirestore'
import { useCardContext } from "../context/CardContext"
import { CardNewbike } from "./CardNewBike"
import { CardBike } from "./CardBike"
import { Container, Row } from "react-bootstrap"
import Modal from 'react-bootstrap/Modal'
import firebase from "firebase/compat"
import PersonIcon from '@mui/icons-material/Person'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { MenuItem, Select, FormControl, InputLabel, Button } from '@mui/material'
import { SpinnerDiamond } from 'spinners-react'

export const BikePanel = () => {

    const [modalLoaderShow, setModalLoaderShow] = useState(true)
    const [cardNewBike, setCardNewBike] = useState(true)
    const [cardBike, setCardBike] = useState([])
    const [user, setUser] = useState([])
    const [modalEditUser, setModalEditUser] = useState(false)
    const [modalLengthShow, setModalLengthShow] = useState(false)
    const [grupo, setGrupo] = useState('Desconocido')

    const {emailUser} = useCardContext()


    useEffect(() => {

        
        const db = getFirestore()
        const dbQuery = db.collection('bicicletas').where('usuario', '==', emailUser.email)
        dbQuery.get()
            .then(res => {
                if (res.docs.length > 0) setCardBike(res.docs.map(item => ({id: item.id, ...item.data()}) )) 
                if (res.docs.length > 3) setCardNewBike(false)
            })
            .catch(err => console.log(err))
            .finally(()=> {
                setModalLoaderShow(false)
            })

    }, [emailUser])

    useEffect(() => {

        const db = getFirestore()
        const userQuery = db.collection('usuarios').where('email', '==', emailUser.email)
            userQuery.get()
                .then(resp => {
                    let userResult = resp.docs.map(item => ( {id: item.id, ...item.data()} ) )
                    let userData = userResult.find(usR => usR.email === emailUser.email)
                    setUser(userData)
                    setGrupo(userData.grupoSanguineo)
                })
                .catch(err => console.log(err))  

    }, [emailUser])

  


    const updateExtraData = (event) => {
        event.preventDefault()
        let dni = event.target.inputDni.value
        let blood = event.target.inputBlood.value
        let trustedName = event.target.trustedName.value
        let trustedPhone = event.target.trustedPhone.value
        if(dni.length > 10 || trustedPhone.length > 20) setModalLengthShow(true)
        else {
            let extraData = {
                id: user.id,
                nombre: emailUser.nombre,
                apellido: emailUser.apellido,
                dni: dni,
                email: emailUser.email,
                grupoSanguineo: blood,
                trustedPerson: trustedName,
                trustedNumber: trustedPhone,
                date: firebase.firestore.Timestamp.fromDate(new Date())
            }

            const dbQuery = getFirestore()
            dbQuery.collection('usuarios').doc(extraData.id).update(extraData)
            .then(() => {
                setUser(extraData)
                setModalEditUser(false)
            })
            .catch(err => console.log(err))
        }
    }


    return (
        <>

            { modalLoaderShow ? <div className="fullScreen bg-light h-100 align-items-center d-flex justify-content-center"><SpinnerDiamond size={100} color='#5b6c7e' /></div> :
                (

                    <div className="marginBoxPanel d-flex justify-content-center">
                        
                                <div className='bg-white rounded formBox p-4 d-flex flex-column align-items-center'>

                                    <h2 className="text-center titularForm mb-3">Tus bicicletas</h2>
                                    
                                    <Container>

                                        <Row className="d-flex justify-content-center">

                                            {cardBike.length > 0 && cardBike.map(item => <CardBike key={item.id} item={item}/>)}

                                            {cardNewBike && <CardNewbike/>}

                                        </Row>
                                        

                                    </Container>

                                    
                                    <Button className="mt-3" onClick={() => setModalEditUser(true)} variant="outlined" startIcon={<PersonIcon/>}>Mis datos</Button>
                                    
                                        
                                
                                </div>


                            <Modal
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                show={modalEditUser}
                                onHide={() => setModalEditUser(false)}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">{user.nombre} {user.apellido}</Modal.Title>
                                </Modal.Header>
                                    <Modal.Body>
                                        <form onSubmit={(e) => updateExtraData(e)}  className="registrationInputs" >

                                            <React.Fragment>
                                                <Grid container spacing={3}>
                                                    <Grid className='mt-4' item xs={12}>
                                                        <Typography variant="h6" gutterBottom>
                                                            Tus datos
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="inputBlood">Grupo Sanguíneo</InputLabel>
                                                        <Select
                                                            labelId="inputBlood"
                                                            id="inputBlood"
                                                            name="inputBlood"
                                                            value={grupo}
                                                            label="Grupo Sanguíneo"
                                                            onChange={e => setGrupo(e.target.value)}
                                                        >
                                                            <MenuItem value={'Desconocido'}>No lo conozco</MenuItem>
                                                            <MenuItem value={'O Negativo'}>0 Negativo</MenuItem>
                                                            <MenuItem value={'O Positivo'}>0 Positivo</MenuItem>
                                                            <MenuItem value={'A Negativo'}>A Negativo</MenuItem>
                                                            <MenuItem value={'A Positivo'}>A Positivo</MenuItem>
                                                            <MenuItem value={'B Negativo'}>B Negativo</MenuItem>
                                                            <MenuItem value={'B Positivo'}>B Positivo</MenuItem>
                                                            <MenuItem value={'AB Negativo'}>AB Negativo</MenuItem>
                                                            <MenuItem value={'AB Positivo'}>AB Positivo</MenuItem>

                                                        </Select>
                                                    </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                    <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                        id="inputDni"
                                                        name="inputDni"
                                                        label="Tu número de DNI"
                                                        fullWidth
                                                        variant="standard"
                                                        defaultValue={user.dni}
                                                    />
                                                    </Grid>

                                                    <Grid className='mt-4' item xs={12}>
                                                        <Typography variant="h6" gutterBottom>
                                                            Datos de tu persona de confianza
                                                        </Typography>
                                                    </Grid>
                                                
                                                    <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        id="trustedName"
                                                        name="trustedName"
                                                        label="Nombre"
                                                        fullWidth
                                                        variant="standard"
                                                        defaultValue={user.trustedPerson}
                                                    />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                    <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                        id="trustedPhone"
                                                        name="trustedPhone"
                                                        label="Teléfono"
                                                        fullWidth
                                                        variant="standard"
                                                        defaultValue={user.trustedNumber}
                                                    />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Button
                                                            type="submit"
                                                            fullWidth
                                                            variant="contained"
                                                            sx={{ mt: 3, mb: 2 }}
                                                            >
                                                            Guardar
                                                        </Button>
                                                    </Grid>

                                                </Grid>
                                            </React.Fragment>

                                        </form>


                                    </Modal.Body>
                            </Modal>


                            <Modal
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                show={modalLengthShow}
                                onHide={() => setModalLengthShow(false)}
                            >
                                <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Revisá los datos.
                                </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                Por favor, verificá tu DNI y/o el teléfono de contacto de tu persona de confiaza.
                                </Modal.Body>
                                <Modal.Footer>
                                <Button onClick={() => setModalLengthShow(false)}>Cerrar</Button>
                                </Modal.Footer>

                            </Modal>

                    </div>

                )
                        
        }

    </>
    )
}