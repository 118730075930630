import Image from 'react-bootstrap/Image';
import logoBr from'../images/logoBr.png';
import Button from 'react-bootstrap/Button'
import { Servicio } from './Servicio';
import { Aliados } from './Aliados';
import { Sticker } from './Sticker';
import { Faq } from './Faq';
import 'animate.css';
import {Link} from 'react-router-dom'
import { useParams } from 'react-router-dom';
import { InfoBike } from './InfoBike';
import { useEffect, useState } from 'react';

export const Main = () => {

    const [isQRPath, setIsQRPath] = useState(false)
    const {idBike} = useParams()

    useEffect(() => {

        typeof idBike !== "undefined" && setIsQRPath(true)

    }, [idBike])

  

    return(

       <>
       
        {isQRPath ? <InfoBike/> : 

            <>

                <div className="backgroundImage">
                    <div className="colorOverlay">
                        <div className='mainElements animate__animated animate__zoomIn mt-5'>
                                <Image className='logoBr' src={logoBr} fluid />
                                <h1 className="titulo">BiciRegistro</h1>
                                <h3 className='bajada'>Sumate a la comunidad más grande de ciclistas de Argentina!</h3>
                                <div className='botonesPrincipales mt-5'>
                                    <Link to="/usr/newAccount"><Button className='botonPanelPrincipal mx-3' variant="dark" size="lg">Registrá tu bici gratis</Button></Link>
                                    <Button href="#sticker" className='linkSticker mt-3' variant="outline-light" size='lg'>Conseguí el sticker para pegarlo en tu bici!</Button>
                                </div>
                                <div className='badge mt-5'>Descargá la app<a rel="noreferrer" target="_blank" href='https://play.google.com/store/apps/details?id=br.com.ar.biciregistro&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img className='gplayBadge' alt='Disponible en Google Play' src='https://play.google.com/intl/es-419/badges/static/images/badges/es-419_badge_web_generic.png'/></a></div>
                        </div>
                    </div>
                </div>
                <div className='mb-5'><Servicio/></div>
                <div className='my-5'><Aliados/></div>
                <div className='mt-5'><Sticker/></div>
                <div className='mb-5'><Faq/></div>

            </>
        } 
       
       </>

    )
}