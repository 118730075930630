import './App.css'
import {NavBar} from './components/NavBar'
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import {Main} from './components/Main'
import React from "react";
import { NewAccount } from './components/NewAccount'
import { NewBike } from './components/NewBike'
import { CardContextProvider } from './context/CardContext'
import { BikeDetails } from './components/BikeDetails'
import { Footer } from './components/Footer'

function App() {


  

  return (
    <CardContextProvider>

      
      <div className="App">
        <header className="App-header">
        <BrowserRouter>
          <NavBar/>

          <Switch>

            <Route exact path='/:idBike?'>
              <Main/>
            </Route>

            <Route exact path='/usr/newAccount/'>
              <NewAccount/>
            </Route>

            <Route exact path='/usr/newBike/'>
              <NewBike/>
            </Route>

            <Route exact path='/usr/bikeDetails/:idBike'>
              <BikeDetails/>
            </Route>

          </Switch>


        </BrowserRouter>
        </header>
      </div>
          <Footer/>

     

    </CardContextProvider>
  )
}

export default App;
